import React, { useEffect, useState } from 'react';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate, useParams} from "react-router-dom";
import {Box} from "@mui/material";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiGetTheoryExam} from "../actions/api";

const UserPage = () => {
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const navigate = useNavigate();
    const {id} = useParams();

    const [theoryExamInfo, theoryExamLoading, theoryExamError, fetchTheoryExam] = useLoadingHook(apiGetTheoryExam);
    const [submitAnswerResult, submitAnswerLoading, submitAnswerError, submitAnswer] = useLoadingHook(apiGetTheoryExam);

    const [currentQuestionId, setCurrentQuestionId] = useState<number | null>(null);
    const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(null);

    useEffect(() => {
        fetchTheoryExam(Number(id));
    }, [id]);

    useEffect(() => {
        if (theoryExamInfo) {
            console.log(theoryExamInfo);
        }
        if (theoryExamError) {
            snackbar.errorMessage(theoryExamError.response?.data?.message ? theoryExamError.response?.data?.message : 'Произошла непредвиденная ошибка');
        }
    }, [theoryExamInfo, theoryExamError]);

    const handleSubmitAnswer = () => {
        submitAnswer({
            question_id: currentQuestionId,
            answer_id: selectedAnswerId
        })
    };



    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>

        </Box>
    );
};

export default UserPage;
