import React, {useEffect, useState} from 'react';
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate, useParams} from "react-router-dom";
import PageHeader from "../../global/components/PageHeader";
import {Avatar, Box, Button, CircularProgress, Stack, TextField, Typography} from "@mui/material";
import {changePassword, getUserById} from "../actions/api";
import {getName} from "../../global/helpers/text";
import {globalInputStyle} from "../../global/helpers/globalstyles";

interface PasswordsDto {
    newPassword: string,
    confirmPassword: string
}

const ChangeUserPassword = () => {

    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();
    const {id} = useParams();
    const [userInfo, setUserInfo] = useState<any>({
        first_name: '',
        last_name: '',
        patronymic: '',
        id,
    });
    const [name, setName] = useState<any>({
        partialName: '',
        fullName: '',
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [passwords, setPasswords] = useState<PasswordsDto>({
        newPassword: '',
        confirmPassword: ''
    });
    const [hidden, setHidden] = useState<boolean>(true);

    useEffect(() => {
        if (!isAdmin && (Number(currentUser.id) !== Number(id))) {
            console.log(`CurrentUser ID: ${currentUser.id}`)
            console.log(`ID: ${Number(id)}`)
            // snackbar.errorMessage(`У вас нет доступа к данной странице`);
            // navigate('/');
        } else {
            fetchUserInfo();
        }
    }, []);

    const fetchUserInfo = () => {
        getUserById(Number(id))
            .then((res) => {
                console.log(res);
                setUserInfo(res.data);
                setName(getName(res.data));
            })
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        const newValue = e.target.value;
        if (/^[a-zA-Z0-9$#!@%]*$/.test(newValue)) {
            setPasswords((prev) => {
                return {
                    ...prev,
                    [e.target.name]: newValue
                }
            })
        } else {
            snackbar.errorMessage(`Пароль может содержать только: латинские символы [a-z, A-Z], цифры (1-9), спец. символы ($,#,!,@,%)`);
        }


    }

    const handleSubmit = () => {
        setLoading(true);
        changePassword(Number(id), passwords.newPassword)
            .then((res) => {
                snackbar.successMessage(`Пароль успешно изменен.`)
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const pagesObj = currentUser.isAdmin ? [
        {
            path: '/',
            title: 'Главная'
        },
        {
            path: '/change-password',
            title: 'Смена пароля'
        },
        {
            path: `/change-password/${id}`,
            title: `Пользователь ${userInfo.last_name} ${userInfo.first_name}`
        },
    ] : [
        {
            path: '/',
            title: 'Главная'
        },
        {
            path: `/change-password/${id}`,
            title: `Смена пароля`
        },
    ]

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>

            <PageHeader
                pages={pagesObj}
            />


            <Typography variant={'h6'}>Выбранный пользователь:</Typography>

            <Stack
                flexDirection={'row'}
                alignItems={'center'}
                sx={{
                    mt: 2,
                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
                    p: 2,
                    borderRadius: 1
                }}
            >
                <Avatar
                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                >
                    {name.partialName ? name.partialName : ''}
                </Avatar>
                <Typography>{name.fullName ? name.fullName : ''} #{userInfo.id}</Typography>
            </Stack>

            <Stack gap={2} sx={{mt: 2}}>
                <TextField
                    name={'newPassword'}
                    sx={globalInputStyle}
                    label={'Новый пароль'}
                    type={hidden ? 'password' : 'text'}
                    value={passwords.newPassword}
                    onChange={handlePasswordChange}
                />

                <TextField
                    name={'confirmPassword'}
                    sx={globalInputStyle}
                    label={'Введите пароль еще раз'}
                    type={hidden ? 'password' : 'text'}
                    value={passwords.confirmPassword}
                    onChange={handlePasswordChange}
                />

                {loading && <Stack flexDirection={'row'} justifyContent={'center'}><CircularProgress /></Stack>}

                {hidden && <Button
                    onClick={() => {setHidden(false)}}
                >
                    Показать пароль
                </Button>}

                {!hidden && <Button
                    onClick={() => {setHidden(true)}}
                >
                    Скрыть пароль
                </Button>}

                {!loading && <Button
                    variant={'contained'}
                    onClick={() => {handleSubmit()}}
                >
                    Сменить пароль
                </Button>}
            </Stack>

        </Box>
    )
};

export default ChangeUserPassword;