import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";

export const apiRequestExam = (dto: CreateTheoryExamDto) => {
    return axiosInstance.post(`${BACKEND_URL}/theory-exams/create`, dto);
}

export const apiGetTheoryExam = (theoryExamId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/theory-exams/${theoryExamId}`);
}

export const apiSubmitAnswer = (dto: SubmitTheoryExamAnswerDto) => {
    return axiosInstance.post(`${BACKEND_URL}/theory-exams/submit-answer`, dto);
}

export interface CreateTheoryExamDto {
    student_id: number;
    category_id: string;
}

export interface CreateTheoryExamResponse {
    message: string,
    theory_exam_id: number
}

export interface SubmitTheoryExamAnswerDto {
    question_id: number,
    answer_id: number
}